<template>
  <cm-table-data :pheaders="headers" :pconfig="config" :pcolumns="columns"></cm-table-data>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      headers: [
        { value: 'id', sortable: false, text: 'Код' },
        { value: 'name', sortable: false, text: 'Наименование' },
        {
          text: 'Действие',
          value: 'actions',
          sortable: false,
        },
      ],
      columns: [
        { value: 'invoice', text: 'invoice', component: 'cmTextField' },
        { value: 'invoiceDate', text: 'invoiceDate', component: 'cmDatePicker' },
        { value: 'receiverId', text: 'receiverId', component: 'cmTextField' },
        { value: 'receptionPlantDate', text: 'receptionPlantDate', component: 'cmDatePicker' },
        {
          value: 'status', text: 'status', store: 'receptionStatuses', component: 'cmList',
        },
        {
          text: 'Действие',
          value: 'actions',
          sortable: false,
        },
      ],
      config: {
        title: {
          main: 'Акт приемки',
          create: 'Создать',
          edit: 'Редактировать',
          delete: 'Удалить',
        },
        endpoints: {
          get: {
            method: 'get',
            url: '/api/v1/wms/reception',
          },
          create: {
            method: 'post',
            url: '/api/v1/wms/reception',
          },
          edit: {
            method: 'put',
            url: '/api/v1/wms/reception',
          },
        },
        permissions: {
          create: false,
          edit: true,
          delete: true,
        },
      },
    };
  },
};
</script>

<style scoped>

</style>
